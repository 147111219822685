
































import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
import {eventBus} from '@/modules/studio/pages/detail/event-bus'
import {utils} from '@/utils/utils'

let debuglog = false
  @Component({
    filters: {
      timeFormat(v: number) {
        return utils.intervalTime(v)
      },
      audioTimeFormat(val: number) {
        let min = val / 60
        let string = ''
        if (min && Math.floor(min) > 0) {
          let sec = Math.floor(val - Math.floor(min) * 60)
          if (sec > 0) {
            string = Math.floor(min) + 'm ' + sec + 's'
          } else {
            string = 60 + 's'
          }
        } else {
          string = Math.floor(val) + 's'
        }
        return string
      }
    }
  })
export default class StudioAudioBar extends Mixins(StudioMsgItemMixins) {
    @Prop({})
    audioReadCache?: any
    @Prop({})
    myMsg?: any
    @Prop({})
    jsonData?: any
    @Prop({})
    seq?: any

    tipsTimer: any = null
    movingTime: number = 0
    currentTime: number = 0
    audioTime: number = 0

    startX: number = 0
    deltaX: number = 0
    defaultLeft: number = 0
    moving: number = 0

    startTime: number = 0
    precent: number = 0
    buffered: number = 0
    state: number = 0 // -1出错 0未加载 1加载中 2播放中 3暂停

    upHandler?: any = null
    moveHandler?: any = null

    get showBar() {
      return [3, 2, 1].indexOf(this.state) !== -1
    }
    get read() {
      return typeof this.audioReadCache[this.audioSeq] !== 'undefined'
    }
    get wrapStyle() {
      let width = (this.jsonData.duration / 60) * 120
      return {
        width: 90 + width + 'px'
      }
    }
    get processStyle() {
      return {
        backgroundColor: '',
        width: this.precent + '%'
      }
    }
    get bufferedStyle() {
      return {
        width: this.buffered + '%'
      }
    }
    get audioSeq() {
      return this.seq || this.msgData.Seq
    }

    created() {
      eventBus.$on('index.liveAudio.canplay', ({seq, audioDom}: any) => {
        if (seq === this.audioSeq) {
          if (this.startTime) {
            audioDom.currentTime = this.startTime
            this.startTime = 0
          }
        } else {
          this.reset()
        }
      })
      eventBus.$on('index.liveAudio.play', ({seq}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '开始 加载')
          this.state = 1
        } else {
          this.reset()
        }
      })
      eventBus.$on('index.liveAudio.loadedmetadata', ({event, seq, audioDom}: any) => {
        if (seq === this.audioSeq) {
          this.precent = 0
          this.audioTime = parseInt(audioDom.duration)
          debuglog && console.info('音频 ', seq, '完成基础加载')
        }
      })
      eventBus.$on('index.liveAudio.playing', ({event, seq}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '开始 播放')
          this.state = 2
        } else {
          this.reset()
        }
      })
      eventBus.$on('index.liveAudio.timeupdate', ({event, seq, audioDom}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '播放进行中')
          this.audioTime = parseInt(audioDom.duration)
          this.currentTime = audioDom.currentTime
          if (!this.moving) {
            this.precent = Math.floor(audioDom.currentTime / audioDom.duration * 100) || 0
          }
          if (audioDom.buffered.length > 0) {
            //            this.state = 2
            this.buffered = Math.floor(audioDom.buffered.end(0) / audioDom.duration * 100) || 0
          }
        }
      })
      eventBus.$on('index.liveAudio.waiting', ({event, seq, audioDom}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '播放缓冲')
          this.state = 1
        } else {
          this.reset()
        }
      })
      eventBus.$on('index.liveAudio.ended', ({event, seq}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '播放结束')
          this.precent = 0
          this.state = 3
        } else {
          this.reset()
        }
      })
      eventBus.$on('index.liveAudio.error', ({event, seq}: any) => {
        if (seq === this.audioSeq) {
          debuglog && console.info('音频 ', seq, '播放出错')
          this.precent = 0
          this.state = -1
        } else {
          this.reset()
        }
      })
      this.upHandler = this.touchEnd.bind(this)
      this.moveHandler = this.touchMove.bind(this)
      // window.document.body.addEventListener('mouseup', this.upHandler, false)
      // window.document.body.addEventListener('mousemove', this.moveHandler, false)
    }
    beforeDestroy() {
      // window.document.body.removeEventListener('mouseup', this.upHandler, false)
      // window.document.body.removeEventListener('mousemove', this.moveHandler, false)
    }


    reset() {
      this.precent = 0
      this.state = 0
    }
    togglePlay() {
      let state = this.state
      if (state === 0 || state === 3 || state === -1) {
        this.play()
      } else {
        this.pause()
      }
    }
    play(startTime?: any) {
      let curAudio = {
        url: this.jsonData.url,
        seq: this.audioSeq,
        time: startTime,
        pptImg: ''
      }
      if (this.jsonData.pptImg) {
        // 播放时添加图片链接
        curAudio.pptImg = this.jsonData.pptImg
      }
      eventBus.$emit('index.setCurrentAudio', curAudio)
      this.startTime = startTime
      if (this.state === -1) {
        eventBus.$emit('index.liveAudio.doload')
      }
      this.$nextTick(() => {
        // 换了连接后再播放
        eventBus.$emit('index.liveAudio.doplay')
        // 设置已读
        eventBus.$emit('index.setReadAudio', {
          seq: this.audioSeq
        })
      })
    }
    pause(timeout?: any) {
      eventBus.$emit('index.liveAudio.dopause')
      if (timeout) {
        // 录音时停止播放音频 停止后会还会触发一下 timeupdate事件把 state 变为2
        // 这里用 timeout 暂时解决
        setTimeout(() => {
          this.state = 3
        }, 500)
      } else {
        this.$nextTick(() => {
          this.state = 3
        })
      }
    }

    processClick (event: any) {
      let time = this.audioTime * (event.layerX / event.target.offsetWidth)
      this.movingTime = time
      this.moving = 1
      clearTimeout(this.tipsTimer)
      this.tipsTimer = setTimeout(() => {
        this.moving = 0
      }, 1000)
      this.precent = time / this.audioTime * 100
      eventBus.$emit('index.setAudioCurrentTime', {time})
    }
    processonClick (event: any) {
      event.stopPropagation()
      let time = this.currentTime * (event.layerX / event.target.offsetWidth)
      this.movingTime = time
      this.moving = 1
      clearTimeout(this.tipsTimer)
      this.tipsTimer = setTimeout(() => {
        this.moving = 0
      }, 1000)
      this.precent = time / this.audioTime * 100
      eventBus.$emit('index.setAudioCurrentTime', {time})
    }
    touchStart (event: any) {
      event.stopPropagation()
      this.startX = event.pageX
      this.defaultLeft = event.target.offsetLeft
      this.moving = 1
    }
    touchEnd (event: any) {
      if (this.moving) {
        event.stopPropagation()
        this.deltaX = event.pageX - this.startX
        let offsetWidth = (this.$refs.bar as HTMLDivElement).offsetWidth
        let moveLeft = this.defaultLeft + 8 + this.deltaX
        if (moveLeft <= 0) {
          moveLeft = 0
        } else if (moveLeft >= offsetWidth) {
          moveLeft = offsetWidth
        }
        let time = this.audioTime * (moveLeft / offsetWidth)
        this.moving = 0
        eventBus.$emit('index.setAudioCurrentTime', {time})
      }
    }
    touchMove (event: any) {
      if (this.moving) {
        event.stopPropagation()
        this.deltaX = event.pageX - this.startX
        let offsetWidth = (this.$refs.bar as HTMLDivElement).offsetWidth
        let moveLeft = this.defaultLeft + 8 + this.deltaX
        if (moveLeft <= 0) {
          moveLeft = 0
        } else if (moveLeft >= offsetWidth) {
          moveLeft = offsetWidth
        }
        this.movingTime = this.audioTime * (moveLeft / offsetWidth)
        this.precent = moveLeft * 100 / offsetWidth
      }
    }
}
