






































import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
import audioBar from './_audio-bar.vue'
import {eventBus} from '@/modules/studio/pages/detail/event-bus'
  @Component({
    components: {
      audioBar
    }
  })
export default class StudioMsgQa extends Mixins(StudioMsgItemMixins) {
    @Prop({})
    audioReadCache?: any
    @Prop({})
    myMsg?: any
    @Prop({})
    curMember?: any

    get jsonData() {
      return JSON.parse(this.msgContent.Data)
    }
    get answerType() {
      return this.jsonData.ABody.type || 'text'
    }
    get questionType() {
      return this.jsonData.QBody.type || 'text'
    }
    get questionContent() {
      return this.jsonData.QBody.content || this.jsonData.QBody
    }

    answer() {
      eventBus.$emit('index.setAnswer', this.msgData)
    }
}
