








































































































import { Component, Vue as VueType, Watch, Prop } from 'vue-property-decorator'
import material from './input-bar-material.vue'
import {eventBus} from '../event-bus'
import {Popover} from 'element-ui'
import StudioInputBarMaterial from '@/modules/studio/pages/detail/components/input-bar-material.vue'
import {isInServer} from '@/utils/utils'

let webim = isInServer() ? {} : window.webim
  @Component({
    components: {
      material,
      [Popover.name]: Popover
    }
  })
export default class StudioInputBar extends VueType {
  @Prop({})
  courseId?: string
  @Prop({})
  courseDetail?: any
  @Prop({})
  liveDetail?: any
  @Prop({})
  curImg?: string
  @Prop({})
  aliveId?: string
  @Prop({})
  groupID?: string
  @Prop({})
  loginInfo?: any
  @Prop({})
  videoVisible?: boolean
  @Prop({})
  liveMode?: string
  @Prop({})
  isAdmin?: boolean
  @Prop({})
  liveState?: number
  @Prop({})
  memberInfo?: any
  @Prop({})
  allShutup?: boolean
  @Prop({})
  answerTarget?: any
  @Prop({})
  curSpeed?: number
  @Prop({})
  unlockData?: any


  beforeVideoVisible: boolean = false
  isAndroid: boolean = false

  // 拓展栏
  extendBarVisible: boolean = false
  toolsPopVisible: boolean = false

  msgSending: boolean = false
  msgType: number = 0 // 0普通信息 1问问题 2答问题
  curText: string = '' // 输入中的文本
  uploadTips: string = ''

  created() {
    eventBus.$on('inputBar.toggleExtendBar', () => {
      this.toggleExtendBar()
    })
  }

  get sendBtnVisible() {
    return this.curText.length > 0 || this.msgType === 2
  }

  get inputMaxLen() {
    let msgTypeMap: any = {
      '0': 999999,
      '1': 200,
      '2': 999999
    }
    let msgType = this.msgType
    return msgTypeMap[msgType]
  }
  // 输入框 placeHolder
  get textPlaceHolder() {
    // 输入栏的 placeHolder 提示
    let msgTypeMap:any = {
      '0': '加入讨论',
      '1': '提问最多输入200字',
      '2': `回答: `
    }
    let msgType = this.msgType
    let text = msgTypeMap[this.msgType]
    if (msgType === 2 && this.answerTarget && this.answerTarget.seq) {
      text += this.answerTarget.sender.nickname
    }
    if (this.isShutup) {
      // 当用户被禁言
      if (this.allShutup) {
        text = '管理员已开启全员禁言'
      } else {
        text = '您已被禁言'
      }
    }
    if (this.msgSending) {
      // 消息发送中
      text = '发送中...'
    }
    if (!this.memberInfo.uid) {
      text = '正在初始化'
    }
    return text
  }

  get isShutup() {
    return this.memberInfo.blocked || (this.allShutup && !this.isAdmin)
  }

  // 信息相关
  // 发信息
  send() {
    if (this.isShutup || this.msgSending) return
    switch (this.msgType) {
      case 1:
        // 问
        this.sendQuestion()
        break
      case 2:
        // 答
        this.sendTextAnswer()
        break
      default:
        // 发文字
        this.sendText()
        break
    }
  }
  materialSend(material: any) {
    let msgType = material.type
    switch (msgType) {
      case 'image':
        // 图片
        const imgArr = material.content instanceof Array ? material.content : [material.content]
        this.sendImgArr(imgArr).then(() => {
          this.toggleExtendBar(false)
        })
        break
      case 'audio':
        // 音频
        this.getRecord({audioUrl: material.content.url, duration: material.content.time}).then(() => {
          this.toggleExtendBar(false)
        })
        break
      case 'text':
        // 文字
        this.sendText(material.content.text).then(() => {
          this.toggleExtendBar(false)
        })
        break
      default:
        console.error('not support this type')
        break
    }
  }
  sendText(text?: string) {
    // 文字
    // 如果 text 有值则是文字素材发起的
    // 跳过长度判断

    // 获取消息内容
    var msgContent = text || webim.Tool.trimStr(this.curText)
    var msgLen = webim.Tool.getStrBytes(msgContent)
    var maxLen, errInfo
    maxLen = text ? 99999 : this.inputMaxLen * 3
    if (msgLen < 1) {
      errInfo = '不能发送空消息'
    } else if (msgLen > maxLen) {
      errInfo = '消息长度超出限制(最多' + Math.round(maxLen / 3) + '汉字)'
    }
    if (errInfo) {
      this.$message(errInfo)
      return Promise.reject(new Error(''))
    }

    let msg = this.createMsg()
    let textObj = new webim.Msg.Elem.Text(msgContent)
    msg.addText(textObj)
    this.curText = ''
    return this.sendMsg(msg)
  }
  sendImgArr(arr: Array<any>) {
    var dealWithItem = (item: any) => this.sendImg(item)
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          var data = []
          for (let i = 0; i < arr.length; i++) {
            data.push(await dealWithItem(arr[i]))
          }
          resolve()
        } catch (e) {
          resolve()
        }
      })()
    })
  }
  sendImg(imgObj: any) {
    // 发送图片
    let msg = this.createMsg()
    let UUID = +(new Date())
    let img = new webim.Msg.Elem.Images('' + UUID)

    let {host: imgHost, file: imgPath} = imgObj.url
    let imgBaseUrl = imgHost + imgPath

    // 1原图 2大图 3小图
    // 加载图片 获取比例
    let jsImg = new window.Image()
    return new Promise((resolve, reject) => {
      jsImg.onerror = jsImg.onload = () => {
        let types = [1, 2, 3]
        types.forEach((type: number) => {
          let width = type === 3 ? jsImg.width : ''
          let height = type === 3 ? jsImg.height : ''
          let resize:any = {
            '1': '',
            '2': '?imageMogr2/auto-orient/thumbnail/750x750',
            '3': '?imageMogr2/auto-orient/thumbnail/350x350'
          }
          let imgUrl = imgBaseUrl + resize[type]
          let newImg = new webim.Msg.Elem.Images.Image(type, 0, width, height, imgUrl)
          img.addImage(newImg)
        })
        msg.addImage(img)
        this.sendMsg(msg).then(resolve, reject)
      }
      jsImg.src = imgBaseUrl + '?imageMogr2/auto-orient/thumbnail/350x350'
    })
  }
  sendQuestion() {
    // 获取消息内容
    var msgContent = webim.Tool.trimStr(this.curText)
    var msgLen = webim.Tool.getStrBytes(msgContent)
    var maxLen, errInfo
    maxLen = this.inputMaxLen * 3
    if (msgLen < 1) {
      errInfo = '不能发送空消息'
    } else if (msgLen > maxLen) {
      errInfo = '消息长度超出限制(最多' + Math.round(maxLen / 3) + '汉字)'
    }
    if (errInfo) {
      this.$message(errInfo)
      return
    }

    let msg = this.createMsg()
    // 约定
    // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form
    msg.addCustom({
      data: JSON.stringify({
        'QBody': webim.Tool.xssFilter(msgContent)         // 问题的内容
      }),
      ext: 'QA'
    })
    this.curText = ''
    this.msgType = 0
    this.sendMsg(msg)
  }
  sendTextAnswer() {
    // 获取消息内容
    var msgContent = webim.Tool.trimStr(this.curText)
    var msgLen = webim.Tool.getStrBytes(msgContent)
    var maxLen, errInfo
    maxLen = this.inputMaxLen * 3
    if (msgLen < 1) {
      errInfo = '不能发送空消息'
    } else if (msgLen > maxLen) {
      errInfo = '消息长度超出限制(最多' + Math.round(maxLen / 3) + '汉字)'
      return
    }
    if (errInfo) {
      this.$message(errInfo)
      return
    }

    this.curText = ''
    this.sendAnswer({
      type: 'text',
      content: webim.Tool.xssFilter(msgContent)
    })
  }
  sendAudioAnswer(recordObj: any) {
    return this.sendAnswer({
      type: 'audio',
      content: recordObj
    })
  }
  sendAnswer(ABody: any) {
    // 获取消息内容
    let msg = this.createMsg()
    // 约定
    // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form
    let msgBody = JSON.parse(this.answerTarget.body[0].MsgContent.Data)
    let qBody = msgBody.QBody
    msg.addCustom({
      data: JSON.stringify({
        'QSeq': this.answerTarget.seq,      // 问题的消息序列
        'QBody': qBody,                     // 问题的内容
        'ABody': ABody
      }),
      ext: 'QA'
    })

    // 发送后自动收起问题区
    eventBus.$emit('index.setQuestionVisible', false)
    return this.sendMsg(msg)
  }
  getRecord({audioUrl, duration}: any) {
    let recordObj = {
      'url': audioUrl,      // 播放地址
      'pptImg': '',
      'duration': duration  // 时长 单位s
    }
    if (this.msgType === 2) {
      return this.sendAudioAnswer(recordObj)
    } else {
      if (this.curImg) {
        // 如果是 ppt 添加当前ppt图片链接
        recordObj.pptImg = this.curImg
      }
      return this.sendRecord(recordObj)
    }
  }
  sendRecord(recordObj: any) {
    let msg = this.createMsg()
    // 约定
    // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form

    msg.addCustom({
      data: JSON.stringify(recordObj),
      ext: 'audio'
    })

    return this.sendMsg(msg)
  }
  sendMsg(msg: any) {
    if (this.isShutup) {
      this.$message('您已被禁言')
      return Promise.reject(new Error(''))
    }
    this.msgSending = true
    return new Promise((resolve, reject) => {
      webim.sendMsg(msg, () => {
        this.msgSending = false
        this.msgType = 0
        // 素材栏收起 再定位
        this.$nextTick(() => {
          //              if (!this.questionVisible) {
          // 定向到自己发的消息
          eventBus.$emit('index.setQuestionVisible', false)
          if (!this.isAdmin) {
            eventBus.$emit('index.setListType', 1)
          } else {
            eventBus.$emit('index.setListType', 0)
          }
          eventBus.$emit('index.toBottom')
          //              }
        })
        resolve()
        console.info('im 发送信息 - 成功')
      }, (err: any) => {
        this.msgSending = false
        let text = err.SrcErrorInfo
        if (err.ErrorCode === 10017) {
          text = '您已被禁言'
        }
        console.info('im 发送信息 - 失败')
        this.$message(text)
        reject(new Error(''))
      })
    })
  }
  createMsg() {
    let {identifier, identifierNick, headurl} = this.loginInfo
    let selToID = this.groupID

    let selType = webim.SESSION_TYPE.GROUP
    let friendHeadUrl = headurl
    var selSess = new webim.Session(
      selType,
      selToID,
      selToID,
      friendHeadUrl,
      Math.round(new Date().getTime() / 1000)
    )

    var isSend = true // 是否为自己发送
    var seq = -1 // 消息序列，-1表示 SDK 自动生成，用于去重
    var random = Math.round(Math.random() * 4294967296) // 消息随机数，用于去重
    var msgTime = Math.round(new Date().getTime() / 1000) // 消息时间戳
    var subType = webim.GROUP_MSG_SUB_TYPE.COMMON
    return new webim.Msg(
      selSess,
      isSend,
      seq,
      random,
      msgTime,
      identifier,
      subType,
      identifierNick
    )
  }

  setMsgType(type: number) {
    this.msgType = type
  }

  // 输入相关
  inputFocus() {
    this.toggleExtendBar(false)
    this.toolsPopVisible = false

    eventBus.$emit('inputBar.focus')
  }
  inputBlur() {
    eventBus.$emit('inputBar.blur')
  }

  toggleExtendBar(nVal?: boolean) {
    (this.$refs.material as StudioInputBarMaterial).toggle(nVal)
  }

  focus() {
    (this.$refs.textareaElm as HTMLInputElement).focus()
  }
  toggleAllShutup() {
    this.$emit('toggleAllShutup')
    this.toolsPopVisible = false
  }
  changeSpeed() {
    eventBus.$emit('index.changeSpeed')
  }
  uploadImg() {
    (this.$refs.fileInput as HTMLInputElement).click()
  }
  inputChanage(event: any) {
    let files = event.target.files
    if (files) {
      (this.$refs.material as StudioInputBarMaterial).sendFiles(files, 'images').then(() => {
        event.target.value = ''
      })
    }
  }
  lineFeed () {
    this.curText = this.curText + '\n'
  }
}
