










import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
  })
export default class StudioHeaderInfo extends Vue {
    @Prop() liveDetail!: any
}
