import { phpApi, pyApi } from '@/api/request'
import * as common from '@/modules/common/models/index'
import * as model from '@/modules/course/models';

export function chatgroup_cert({live_id, ticket}: any): Promise<any> {
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/lives/${live_id}/chatgroup/cert/`,
    data: {
      ticket
    }
  })
}

export function chatgroup_qa({live_id, answered, page, size}: any): Promise<any> {
  let params: any = {
    page,
    size
  }
  if (typeof answered !== 'undefined') {
    params['answered'] = answered
  }
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/lives/${live_id}/qa/`,
    params
  })
}

export function chatgroup_messages({live_id, only_streamer, seq, size}: any): Promise<any> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/lives/${live_id}/chatgroup/messages/`,
    params: {
      only_streamer,
      seq,
      size
    }
  })
}

export function chatgroup_messages_delete({live_id, seq}: any): Promise<any> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/lives/${live_id}/chatgroup/messages/`,
    params: {
      sequences: [seq]
    }
  })
}

export function chatgroup_member({live_id, members}: any): Promise<any> {
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/lives/${live_id}/chatgroup/members/`,
    data: {
      members
    }
  })
}

export function chatgroup_blockmember({live_id, members, block}: any): Promise<any> {
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/lives/${live_id}/chatgroup/blockmember/`,
    data: {
      members,
      block
    }
  })
}

export function admire_money({content_id, lecturer, money, lecturer_name, return_url}: any): Promise<any> {
  return phpApi({
    method: 'POST',
    url: `/h5/admire/order`,
    data: {
      content_id,
      lecturer,
      money,
      lecturer_name,
      return_url
    }
  })
}

export function material_list({type}: any): Promise<any> {
  return phpApi({
    method: 'GET',
    url: `/h5/material/lists`,
    params: {
      type,
      page: 1,
      count: 999
    }
  })
}

export function blockall({liveId, block}: any): Promise<any> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/lives/${liveId}/chatgroup/blockall/`,
    data: {
      block
    }
  })
}

// client-获取直播人数加成设置
// wiki: http://yapi.hogecloud.com/project/11/interface/api/953
export function getLiveCountSetting() {
  return phpApi({
    method: 'GET',
    url: `/h5/alive/online/setting`
  })
}

// client-获取直播聊天室门票
// wiki: http://yapi.hogecloud.com/project/11/interface/api/5215
export function getChatgroupTicket({liveId}: any) {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v1/lives/${liveId}/chatgroup/ticket/`
  })
}

