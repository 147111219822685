






























































































import { Component, Vue as VueType, Watch, Prop } from 'vue-property-decorator'
import * as studioApi from '@/modules/studio/api'
import { utils , getMyHost } from '@/utils/utils'
import { Dialog } from 'element-ui'
import NavTab from '@/modules/common/components/nav-tab.vue'
import ContentBox from '@/modules/common/components/content-box.vue'
import { getUploadSign } from '@/modules/common/api'
import Vue from 'vue'

let components: any = {
  ContentBox,
  [Dialog.name]: Dialog,
  NavTab
}
if (!Vue.prototype.$isServer) {
  let Scroll = require('cube-ui/lib').Scroll;
  components.Scroll = Scroll
}

@Component({
  filters: {
    timeFormat(v: number) {
      return utils.intervalTime(v)
    },
    imgUrlFormat(v: any) {
      return v.host + v.file
    }
  },
  components
})
export default class StudioInputBarMaterial extends VueType {
    @Prop({})
    liveMode?: string
    @Prop({})
    aliveId?: string
    @Prop({})
    liveState?: number

    tabList: Array<any> = [
      { name: '图片', value: 0 },
      { name: '音频', value: 1 },
      { name: '文字', value: 2 }
    ]
    sending: boolean = false
    selectIdx: number = -1
    imgSelectArr: Array<any> = []
    dataLoading: boolean = false
    materialType: number = 0
    materialArr: Array<any> = [[], [], []]
    visible: boolean = false
    imgUploading: boolean = false
    uploadTips: string = ''
    cos: any = null
    scrollOptions: any = {
      mouseWheel: {
        speed: 20,
        invert: false,
        easeTime: 0
      },
      scrollbar: {
        fade: true
      }
    }

    created() {
      if (Vue.prototype.$isServer) return
      this.initUploader()
    }

    get myUrl() {
      let types = ['picture', 'audio', 'text']
      let requestType = this.materialType
      return `${getMyHost()}/material/manage/${types[requestType]}`
    }
    get materialList() {
      return this.materialType !== -1 ? this.materialArr[this.materialType] : []
    }
    set materialList(arr: Array<any>) {
      this.$set(this.materialArr, this.materialType, arr)
    }
    get selectedItem() {
      return this.selectIdx !== -1 ? this.materialList[this.selectIdx] : null
    }
    get canSend() {
      return this.selectIdx !== -1 || this.imgSelectArr.length > 0
    }

    @Watch('materialType', {immediate: true})
    onMaterialTypeChanged(nVal: number) {
      if (nVal !== -1 && this.materialArr[nVal].length < 1) {
        this.loadData().then(e => {
          this.scrollUpdate()
        })
      }
    }

    initUploader() {
      let CosCloud = require('cos-js-sdk-v4')
      let cos = new CosCloud({
        appid: '1253562005', // APPID 必填参数
        bucket: 'sina-duanshu', // bucketName 必填参数
        region: 'sh', // 地域信息 必填参数 华南地区填gz 华东填sh 华北填tj
        getAppSign(callback: any) { // 获取签名 必填参数
          getUploadSign().then(e => {
            callback(e.token)
          })
        }
      })
      this.cos = cos
    }
    send() {
      if (this.materialType === 0) {
        // 图片
        if (this.imgSelectArr.length > 0) {
          let contents = this.imgSelectArr.map(e => this.materialList[e].content)
          this.sending = true
          this.$emit('send', {
            type: 'image',
            content: contents
          })
          this.imgSelectArr = []
        } else {
          this.$message('请选择素材')
        }
      } else {
        let content = this.selectedItem
        if (content) {
          this.sending = true
          this.$emit('send', content)
        } else {
          this.$message('请选择素材')
        }
      }
    }

    loadData() {
      let types = ['image', 'audio', 'text']
      let requestType = this.materialType
      let type = types[requestType]
      this.dataLoading = true
      return studioApi.material_list({type}).then(e => {
        this.dataLoading = false
        let data = e.data
        this.$set(this.materialArr, requestType, data)
        this.scrollUpdate()
      }).catch(e => {
        this.dataLoading = false
        this.$message('取素材失败')
      })
    }
    reload() {
      this.$set(this.materialArr, this.materialType, [])
      this.loadData()
      this.imgSelectArr = []
    }

    toggle(nVal?: boolean) {
      if (typeof nVal === 'undefined') {
        this.visible = !this.visible
      } else {
        this.visible = nVal
      }
      if (this.sending) {
        this.$message('发送成功')
      }
      this.sending = false
      this.imgSelectArr = []
      this.selectIdx = -1
    }
    toggleHandler(v: number) {
      this.materialType = v
    }

    imgSelect(i: number) {
      let max = 9
      let idx = this.imgSelectArr.indexOf(i)
      let selected = idx !== -1
      if (selected) {
        this.imgSelectArr.splice(idx, 1)
      } else {
        if (this.imgSelectArr.length < max) {
          this.imgSelectArr.push(i)
        } else {
          this.$message('最多' + max + '张')
        }
      }
    }
    handleClose() {
      this.toggle(false)
    }
    async fileUpload (files: Array<any>) {
      let cos = this.cos
      let successArr = []
      let errorArr = []
      if (files.length > 9) {
        this.$message(`最多上传${9}张`)
        return
      }
      this.$emit('uploadTips', `上传中`)
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let format = file.name.split('.').pop() ? ('.' + file.name.split('.').pop()) : ''
        let name = [+new Date(), Math.floor(Math.random() * 6)].join('_') + format
        let url = [ 'feedback', name ].join('/')
        let imgObj = {}
        try {
          this.$emit('uploadTips', `上传中${i + 1}/${files.length}`)
          imgObj = await new Promise((resolve, reject) => {
            cos.uploadFile((result: any) => {
              resolve(result)
            }, (result: any) => {
              reject(result.responseText || 'error')
            }, () => {}, 'sina-duanshu', url, file, 1)
          })
          successArr.push(imgObj)
        } catch (e) {
          errorArr.push(imgObj)
        }
      }
      if (errorArr.length > 0) {
        this.$message(errorArr.length + '张图片上传失败')
      }
      this.$emit('uploadTips', ``)
      successArr = successArr.map((e: any) => {
        return e.data.access_url
      })
      return successArr
    }
    sendFiles(files: Array<any>, type: string) {
      // if (files.length > 0) {
      //   this.fileUpload(files).then(e => {
      //     console.info(e)
      //   })
      // }
      return new Promise((resolve, reject) => {
        this.$emit('uploadTips', '上传中')
        setTimeout(() => {
          this.$emit('uploadTips', '')
          this.$emit('send', {
            type: 'image',
            content: [{
              file_name: '07.jpg',
              size: '169',
              url: {
                file: '/2019/03/01/17/j4587306b30921gdeb/common/content/1551432175759_296941.jpg',
                host: 'https://duanshu-1253562005.picsh.myqcloud.com',
                query: ''
              }
            }, {
              file_name: '07.jpg',
              size: '169',
              url: {
                file: '/2019/03/01/17/j4587306b30921gdeb/common/content/1551432175759_296941.jpg',
                host: 'https://duanshu-1253562005.picsh.myqcloud.com',
                query: ''
              }
            }]
          })
          resolve()
        }, 3000)
      })
    }

    scrollUpdate() {
      let scroll: any = this.$refs.scroll
      if (scroll) {
        scroll.forceUpdate()
      }
    }
}
