

























































import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import msgText from './text.vue'
import msgImg from './img.vue'
import msgAdmire from './admire.vue'
import msgAudio from './audio.vue'
import msgError from './error.vue'
import msgQa from './qa.vue'
import StudioMemberCacheMixins from './memberCache'
import { isInServer } from '@/utils/utils'

let webim = isInServer() ? {} : window.webim
@Component({
  components: {
    msgText,
    msgImg,
    msgQa,
    msgAudio,
    msgError,
    msgAdmire
  }
})
export default class StudioInputBar extends Mixins(StudioMemberCacheMixins) {
  @Prop({})
  showTrueName?: boolean
  @Prop({})
  idx?: number
  @Prop({})
  listType?: number
  @Prop({})
  liveId?: string
  @Prop({})
  msgData?: any
  @Prop({})
  isAdmin?: boolean
  @Prop({})
  audioReadCache?: any
  @Prop({})
  curMember?: any

  showTools: boolean = false
  bgYellow: boolean = false

  get userDetail() {
    return this.$store.state.userInfo
  }
  get trueName() {
    let name1 = this.msgData.sender.true_name
    let name2 = this.userDetail.true_name
    let name3 = this.memberInfo.true_name
    return this.myMsg ? name2 : (name1 || name3)
  }
  get msgSenderName() {
    let name = this.memberInfo.nickname
    if (this.memberInfo.is_streamer && this.memberInfo.nick_name) {
      // 讲师有别名
      name = this.memberInfo.nick_name
    }
    return name
  }
  get memberInfo() {
    let memberId = this.msgData.sender.uid
    return this.memberCacheGetMemberInfo(memberId)
  }
  get tag() {
    return this.memberInfo.is_streamer ? this.memberInfo.tag : ''
  }
  get myMsg() {
    return this.msgData.sender.uid === this.curMember.uid
  }
  get msgType() {
    let curType = this.msgData.body[0].MsgType
    let type = webim.MSG_ELEMENT_TYPE
    let clsMap = {
      [type.TEXT]: 'text', // 文本
      [type.IMAGE]: 'img', // 图片
      [type.CUSTOM]: 'custom' // 自定义
    }
    let mapType = clsMap[curType]
    if (mapType === 'custom') {
      // 自定义消息约定
      // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form
      let customMap: any = {
        QA: 'QA',
        admire: 'admire',
        audio: 'audio'
      }
      mapType = customMap[this.msgData.body[0].MsgContent.Ext]
    }
    return mapType || 'error' // 不明消息不做渲染
  }
  get msgClass() {
    return [this.myMsg ? 'self-msg' : '', this.showTools ? 'height-idx' : '']
  }

  heighLight() {
    this.bgYellow = true
    setTimeout(() => {
      this.bgYellow = false
    }, 1000)
  }
  getMemberHandler(member: any) {
    this.$emit('getMember', member)
  }
}
