













































import { Component, Vue, Prop } from 'vue-property-decorator'
import CountDown from '@/modules/common/components/count-down.vue'
import { Dialog } from 'element-ui'

  @Component({
    components: {
      CountDown,
      [Dialog.name]: Dialog
    }
  })
export default class StudioHeaderInfo extends Vue {
    @Prop() liveDetail!: any
    @Prop() liveState!: any

    visible: boolean = true
    show() {
      this.visible = true
    }
    hide(action?: string) {
      this.visible = false
      this.$emit('ok', action)
    }
}
