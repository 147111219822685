




import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
import AudioBar from './_audio-bar.vue'
import StudioAudioBar from '@/modules/studio/pages/detail/components/msg/_audio-bar.vue'
  @Component({
    components: {
      AudioBar
    }
  })
export default class StudioMsgAudio extends Mixins(StudioMsgItemMixins) {
    @Prop({})
    audioReadCache?: any
    @Prop({})
    myMsg?: any

    get jsonData() {
      return JSON.parse(this.msgContent.Data)
    }

    play(startTime: number) {
      (this.$refs.audioBar as StudioAudioBar).play(startTime)
    }
    pause() {
      (this.$refs.audioBar as StudioAudioBar).pause()
    }
}
