

























import { Component, Vue as VueType, Watch, Prop } from 'vue-property-decorator'
import countDown from '@/modules/common/components/count-down.vue'
import {getLiveCountSetting} from '@/modules/studio/api'

  @Component({
    components: {
      countDown
    }
  })
export default class StudioStateBar extends VueType {
    @Prop({})
    liveDetail?: any
    @Prop({})
    liveState?: number
    @Prop({default: 0})
    liveCount?: number

    countSetting: any = {}

    created() {
      this.getCountSetting()
    }

    get afterCount() {
      let base: any = this.liveCount
      if (this.countSetting.multiple) {
        base = base * this.countSetting.multiple
      }
      if (this.countSetting.base) {
        base = base + parseInt(this.countSetting.base)
      }
      return Math.floor(base) || this.liveCount
    }

    get userDetail() {
      return this.$store.state.userInfo
    }

    get isLecturer() {
      let plist = this.liveDetail.live_person
      let flag = false
      let {uid} = this.userDetail
      for (let i = 0; i < plist.length; i++) {
        if (plist[i].id === uid) {
          flag = true
          break
        }
      }
      return flag || !!this.liveDetail.lecturer
    }

    get isShowAfterCount() {
      if (this.liveDetail.hide_online_member_count) {
        return false
      } else {
        // total_online_display_to_streamer_only 仅讲师可看到在线人数
        if (this.liveDetail.total_online_display_to_streamer_only) {
          return this.isLecturer
        } else {
          return !!this.afterCount
        }
      }
    }

    getCountSetting() {
      getLiveCountSetting().then(e => {
        this.countSetting = e
      }).catch(err => {
        this.$message(`liveCount繁忙：${err.errorObj.title}`)
      })
    }

}
