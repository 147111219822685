





import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
  @Component({
  })
export default class StudioMsgError extends Mixins(StudioMsgItemMixins) {
}
