































































































import { Component, Vue as VueType, Watch, Prop } from 'vue-property-decorator'
import * as utils from '@/utils/utils'
import fVideo from '@/modules/common/components/g-video.vue'
import fHlsVideo from '@/modules/common/components/g-hls-video.vue';
import {eventBus} from '../event-bus'
let debugLog = true

  @Component({
    components: {
      fHlsVideo,
      fVideo
    },
    filters: {
      timeSn(v: number) {
        let range = v
        let hour = operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)))
        let min = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60))
        let sec = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60))
        let str: any = hour > 0 ? [hour] : []
        str = str.concat([min, sec]).join(':')
        return str
        function operateTime(time: number) {
          return time < 10 ? '0' + time : time
        }
      }
    }
  })
export default class StudioVideoArea extends VueType {
    @Prop({})
    liveDetail?: any
    @Prop({})
    liveState?: number

    afterPlay: boolean = false
    videoStart: boolean = false
    videoMsg: string = ''
    historyTime: number = 0
    historyTips: boolean = false
    videoExt: string = ''
    videoExtMaskVisible: boolean = false
    autoPlayCheck: any = null

    showRetry: boolean = false
    beforeReTryVideoCurrent: number = 0
    reTryCount: number = 3
    reTryTimer: any = null

    videoSpeedArr: Array<number> = [1, 1.25, 1.5, 1.75, 2]
    videoSpeed: number = 0

    maskType: number = 0 // 0
    videoStatus: any = {
      nStatus: ''
    }
    contentPath: string = '' // 先用空的路径给组件 不加载路径以免触发事件
    historyTipsTimer: number = 0
    playData: any = {}
    supportFlv: boolean = false

    created() {
      if (window.MediaSource) {
        this.supportFlv = window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"')
      }
      this.videoExt = this.supportFlv ? 'flv' : 'm3u8'
    }

    beforeDestroy() {
      this.endRetry()
    }


    get canBackToHistory() {
      // live_type 1语音 2视频 3自定义 4Obs 5手机
      let type = this.liveDetail.live_type
      let flow = this.liveDetail.obs_flow
      let flag = false
      if (type === 2) {
        flag = true
      }
      if (type !== 2 && this.liveState === 2) {
        // 已结束
        if (flow && flow.obs_reply_url) {
          // 有回放地址(自定义直播 没有回放地址)
          flag = true
        }
      }
      return flag
    }
    // 直播路径
    get videoPath() {
      let flow = this.liveDetail.obs_flow
      let path
      // live_type 1语音 2视频 3自定义 4Obs 5手机
      let type = this.liveDetail.live_type
      //        let path = 'http://1253562005.vod2.myqcloud.com/e4a62924vodtransgzp1253562005/539f42a27447398155300328044/v.f230.m3u8'
      // 自定义直播不用 obs_url
      if (type === 3) {
        path = this.liveDetail.live_flow
      } else if (type === 2) {
        path = this.liveDetail.video_path
      } else {
        path = flow.obs_url
        path = path.replace('.m3u8', `.${this.videoExt}`)
      }

      if (type !== 2 && this.liveState === 2) {
        // 已结束
        if (flow && flow.obs_reply_url) {
          // 有回放地址(自定义直播 没有回放地址)
          path = flow.obs_reply_url
        } else if (type === 4 || type === 5) {
          path = ''
        }
      }
      return path
    }
    get maskBgImg() {
      return {
        backgroundImage: 'url(' + utils.utils.createImgSrc(this.liveDetail.live_indexpic, {
          width: 700,
          height: 420
        }) + ')'
      }
    }
    get techers() {
      let techers: Array<any> = []
      this.liveDetail.live_person.forEach((e: any) => {
        let name = e.nick_name || e.name
        let show = !e.hidden
        if (show) {
          techers.push(name + '(' + e.tag + ')')
        }
      })
      let max = 3
      let len = techers.length
      let techerString = techers.slice(0, max).join('、')
      if (len > max) {
        techerString += '...等' + len + '人播讲'
      }
      return techerString
    }
    get curVideoSpeed() {
      return this.videoSpeedArr[this.videoSpeed]
    }
    get showSpeedBtn() {
      // 非安卓(安卓不支持) 有播放路径
      return this.videoPath
    }
    get isLive() {
      // live_type 1语音 2视频 3自定义 4Obs 5手机
      let type = this.liveDetail.live_type
      let live = false
      if (type === 4 || type === 5) {
        live = this.liveState !== 2
      }
      return live
    }
    get videoExtBtnVisible() {
      // live_type 1语音 2视频 3自定义 4Obs 5手机
      let typeFlag = this.liveDetail.live_type === 4 || this.liveDetail.live_type === 5
      let stateFlag = this.liveState === 1
      return typeFlag && stateFlag
    }

    videoTcEvent(eData: any) {
      let eventType = eData.type
      if (!this.afterPlay) {
        // 初始化视频也会触发事件 这里 不是在执行 组件 play() 后的事件 不处理
        return
      }
      // 安卓初始化视频 会触发 load error
      // ios初始化视频 会触发 load
      if (eventType === 'play' || eventType === 'load') {
        clearTimeout(this.autoPlayCheck)
      } else if (eventType === 'timeupdate') {
        // 播放进行中
        let curTime = (this.$refs.videoPlay as fVideo).qcVideo.currentTime()
        if (curTime > 0) {
          this.videoMsg = ''
          this.videoStart = true
        }
        eventBus.$emit('index.setCurrentVideoTime', curTime)
      } else if (eventType === 'obsend' || eventType === 'error') {
        this.beforeReTryVideoCurrent = (this.$refs.videoPlay as fVideo).qcVideo.currentTime()
        this.retry('正在为你努力重连...请耐心等待')
      }
    }
    retry(msg: string) {
      // 重试逻辑 重试 5次
      // 再失败显示 信息层
      this.videoStart = false
      this.showRetry = false
      if (this.reTryCount > 0) {
        debugLog && console.info('开始重试')
        // 还有重试次数
        clearTimeout(this.reTryTimer)
        this.reTryCount--
        this.reTryTimer = setTimeout(() => {
          this.play(msg)
          if (this.liveState === 2) {
            // 回看
            (this.$refs.videoPlay as fVideo).qcVideo.currentTime(this.beforeReTryVideoCurrent)
          }
        }, 1000)
      } else {
        // 没有重试次数 显示 断连
        this.videoStart = false
        this.showRetry = true
      }
    }
    endRetry() {
      debugLog && console.info('结束重试')
      clearTimeout(this.reTryTimer)
    }
    reTryBtn(msg?: string) {
      this.reTryCount = 5
      this.videoMsg = msg || '正在为你努力重连...请耐心等待'
      this.retry('')
    }
    play(msg: string = '') {
      debugLog && console.info('video-area play()')
      if (this.liveDetail.live_type === 2) {
        this.videoMsg = msg
        this.afterPlay = true
        let {file_id: fileId, token} = this.liveDetail
        ;(this.$refs.videoPlay as fHlsVideo).reloadPlayer({
          fileId,
          token
        }).then(() => {
          ;(this.$refs.videoPlay as fHlsVideo).playVideo()
        })
      } else if (this.videoPath) {
        this.videoMsg = msg
        this.afterPlay = true
        ;(this.$refs.videoPlay as fVideo).destroyPlayer()
        ;(this.$refs.videoPlay as fVideo).initPlayer()
        ;(this.$refs.videoPlay as fVideo).playVideo()
        clearTimeout(this.autoPlayCheck)
        this.autoPlayCheck = setTimeout(() => {
          // 因为 上面操作会 销毁 video
          // 执行 play 方法可能会被浏览器阻止
          // qcVideo 的 play() 方法不会返回 失败的情况
          // 这里 延迟 1s 如果失败就显示 播放按钮让用户手动 再播放
          let playing = (this.$refs.videoPlay as fVideo).qcVideo.playing()
          if (!playing) {
            this.videoMsg = ''
            this.videoStart = true
          }
        }, 1000)
      }
    }
    stop() {
      debugLog && console.info('video-area stop()')
      this.afterPlay = false
      this.videoStart = false
      if (this.liveDetail.live_type === 2) {
        ;(this.$refs.videoPlay as fHlsVideo).pause() // 重新加载链接
      } else {
        ;(this.$refs.videoPlay as fVideo).reloadPlayer() // 重新加载链接
      }
    }
    hideHistory() {
      clearTimeout(this.historyTipsTimer)
      this.historyTips = false
    }
    showHistory(cache: any) {
      let cacheTime = cache.currentVideoTime
      if (cacheTime > 0 && this.liveState === 2) {
        this.historyTime = cacheTime
        this.historyTips = true
        this.$nextTick(() => {
          (document.querySelector('#videoPlay video') as HTMLMediaElement).currentTime = cacheTime
        })
        this.historyTipsTimer = setTimeout(() => {
          this.hideHistory()
        }, 5000)
      }
    }
    toHistory() {
      (this.$refs.videoPlay as fVideo).qcVideo.currentTime(this.historyTime)
      this.hideHistory()
    }
    toggleVideoExtMask() {
      this.videoExtMaskVisible = !this.videoExtMaskVisible
    }
    changeVideoExt(val: string) {
      this.videoExtMaskVisible = false
      this.videoExt = val
      this.reTryBtn('正在为你切换...请耐心等待')
    }
    changeSpeed(times: number) {
      (this.$refs.videoPlay as fVideo).changeSpeed(times)
    }
}
