



































































































































































import { Component, Vue as VueType, Watch } from 'vue-property-decorator'
import ContentLayout from '@/modules/studio/components/content-layout.vue'
import StudioHeaderInfo from '@/modules/studio/pages/detail/components/header-info.vue'
import StudioCourseInfo from '@/modules/studio/pages/detail/components/course-info.vue'
import StudioCodeGuide from '@/modules/studio/pages/detail/components/code-guide.vue'
import ClassTracking from '@/modules/course/pages/course-class/class-tracking'
import LiveDesc from '@/modules/studio/pages/detail/components/live-desc.vue'
import ClassList from '@/modules/course/pages/course-class/components/class-list/index.vue'
import VideoArea from '@/modules/studio/pages/detail/components/video-area.vue'

import EnterDialog from '@/modules/studio/pages/detail/components/enter-dialog.vue'
import StateBar from '@/modules/studio/pages/detail/components/state-bar.vue'
import MsgItem from '@/modules/studio/pages/detail/components/msg/msg-item.vue'
import InputBar from '@/modules/studio/pages/detail/components/input-bar.vue'
import {Route} from 'vue-router/types'
import {Image} from 'element-ui'
import CourseClassMaterial from '@/modules/course/pages/course-class/components/class-material.vue'
import SpeedBtn from '@/modules/common/components/speed-btn.vue'

import { getContentDetail } from '@/modules/content/api'
import * as studioApi from '@/modules/studio/api'

import * as utils from '@/utils/utils'
import { getClassCourseDetail, getCourseDetail } from '@/modules/course/api'
import Vue from 'vue'
import {
  StudioAudioCache,
  StudioAudioCurrent,
  StudioGroupInfo,
  StudioLiveDetail,
  StudioLoginInfo,
  StudioMemberInfo,
  StudioMsgData
} from '../../models'
import {CourseInfo} from '@/modules/course/models'
import {eventBus} from './event-bus'
import ComponentTopHeader from '@/modules/common/components/top-header.vue'
import { ClassTrackingV2 } from '@/modules/course/pages/course-class/class-tracking-v2';

let webim = utils.isInServer() ? {} : window.webim
let debuglog = true

let components: any = {
  ContentLayout,
  CourseClassMaterial,
  StudioHeaderInfo,
  StudioCourseInfo,
  StudioCodeGuide,
  LiveDesc,
  MsgItem,
  EnterDialog,
  ClassList,
  [Image.name]: Image,
  StateBar,
  InputBar,
  VideoArea,
  SpeedBtn
}
if (!Vue.prototype.$isServer) {
  let Scroll = require('cube-ui/lib').Scroll;
  components.Scroll = Scroll
}

  @Component({
    components
  })
export default class StudioDetail extends VueType {
  from?: Route
  courseId?: string
  showClassList: boolean = false
  classId?: string
  courseDetail: any = {}
  aliveId: any = ''

  audioLoading: boolean = true // 因 ios 不能非用户行为播放语音 因此 全量加载历史消息才开放用户点击
  imLoading: boolean = true
  loading: boolean = true
  errorObj: any = null

  localTimer?: number
  localTime: number = 0
  liveDetail: StudioLiveDetail = {} // 直播间详情
  loginInfo: StudioLoginInfo = {} // im 登录信息
  beforeVideoVisible: boolean = true
  videoVisible: boolean = true
  currentVideoTime: number = 0
  countSetting: any = {} // 人数设置

  groupID: any = '' // 聊天室
  groupInfoLoopTimer?: number
  groupInfoLoopTime: number = 10 // 在线人数轮询 单位s
  groupInfo: StudioGroupInfo = {} // 聊天室信息

  iosMask: boolean = false
  newQuestion: boolean = false
  listType: number = 1 // 0讲师区 1讨论区
  listArr: Array<any> = [[], []] // 消息列表 [[讲师区] [讨论区]]
  pageSeqArr: Array<number> = [9999, 9999] // 历史消息接口返回的 previous_seq
  pageSeqLoadingArr: Array<boolean> = [false, false]
  unseeCountArr: Array<number> = [0, 0]
  curScroll: number = 1 // 当前滚动值
  autoToBottom: boolean = true // 新消息自动置底
  scrollOptions: any = {
    mouseWheel: {
      speed: 20,
      invert: false,
      easeTime: 0
    },
    scrollbar: {
      fade: true
    }
  }

  // 倍速播放
  mediaSpeed: number = 1

  // 问题栏
  answerTarget: StudioMsgData | null = null
  questionVisible: boolean = false

  // 悬赏
  admireTargetMember: any = {}

  // 音频
  currentAudio: StudioAudioCurrent = {
    seq: '',
    url: '',
    time: '',
    pptImg: ''
  }
  audioReadCache: StudioAudioCache = {} // 已读缓存

  memberCacheTimer?: number // 节流
  memberCacheArr: Array<StudioMemberInfo> = []
  memberCache: any = {}

  qrcodeVisible: boolean = false
  qrcodeDialog: any = {
    followQrcode: '',
    textObj: {}
  }

  leaveCache: any = {}
  curSpeed: string = '1.0'
  speedArr: Array<string> = ['0.7', '1.0', '1.25', '1.5', '1.75', '2.0']
  unlockGuidePop: boolean = false
  unlockData: Array<any> = []
  unloadHandler: any = () => {}
  previewImageUrl: string = ''
  allShutupVisible: boolean = false
  allShutupVisibleTimer: any = null
  showMsgTips: boolean = false

  ticketTimer: any = null
  ticketMsg: any = ''
  ticketEnd: boolean = true
  trackObj: any = null

  created() {
    let courseId = this.$route.params.courseId
    let aliveId = courseId ? this.$route.query.classContentId : this.$route.params.id
    let cacheId = this.$route.params.id
    let leaveCache = {}
    if (!Vue.prototype.$isServer) {
      leaveCache = this.getLeaveCache()[cacheId] || {}
    }
    this.classId = this.$route.params.id
    this.localTime = this.getTime()
    this.courseId = courseId
    this.aliveId = aliveId
    this.groupID = aliveId
    this.leaveCache = leaveCache

    // 清空 重置
    eventBus.$off()

    // 添加
    eventBus.$on('index.setListType', (e: number) => {
      // this.listType = e
    })
    eventBus.$on('index.setQuestionVisible', (e: boolean) => {
      this.questionVisible = e
    })
    eventBus.$on('index.setCurrentAudio', (e: StudioAudioCurrent) => {
      let curSeq = this.currentAudio.seq
      if (e.seq !== curSeq) {
        let liveAudio: any = this.$refs.liveAudio
        liveAudio.pause()
        this.currentAudio = Object.assign({}, this.currentAudio, e)
        liveAudio.load()
      }
    })
    eventBus.$on('index.setCurrentVideoTime', (e: number) => {
      this.currentVideoTime = e
    })
    eventBus.$on('index.setVideoAreaVisible', (e: any) => {
    })
    eventBus.$on('index.scrollUpdate', this.scrollUpdate.bind(this))
    eventBus.$on('index.toBottom', this.toBottom.bind(this))
    eventBus.$on('inputBar.focus', () => {
      this.iosMask = true
    })
    eventBus.$on('inputBar.blur', () => {
      this.iosMask = false
      // iphoneX 收起键盘 页面没重置
      window.scrollTo(0, 0)
    })
    eventBus.$on('index.changeSpeed', () => {
      let curIdx = this.speedArr.indexOf(this.curSpeed)
      let nextIdx = curIdx + 1
      if (nextIdx < this.speedArr.length) {
        this.curSpeed = this.speedArr[nextIdx]
      } else {
        this.curSpeed = this.speedArr[0]
      }
    })
    eventBus.$on('index.setAnswer', (e: any) => {
      this.handleAnswer(e)
    })
    eventBus.$on('index.previewImg', (e: any) => {
      this.previewImageUrl = e
      ;(this.$refs.elPreviewImage as any).clickHandler()
    })

    // 安卓弹出层时 避免视频最高层级影响 先隐藏视频
    eventBus.$on('index.androidSometingMaskShow', (e: any) => {
    })
    eventBus.$on('index.androidSometingMaskHide', (e: any) => {
    })

    let unloadHandler = (e: any) => {
      this.setLeaveCache()
      this.logout()
    }
    if (!utils.isInServer()) {
      window.addEventListener('unload', unloadHandler, false)
    }
    this.unloadHandler = unloadHandler
  }
  mounted() {
    if (!webim) {
      this.errorObj = {
        html: 'webim sdk 加载失败'
      }
      return
    }
    this.loopImTicket().then((ticket: any) => {
      //  用户信息
      this.$store.dispatch('GET_USER_INFO').then((e: any) => {
        // 详情信息初始化完成 && im登录流程完成
        let lock = Promise.resolve()
        lock.then((e: any) => {
          this.initDetail().then(e => {
            if (this.courseId) {
              this.trackObj = new ClassTrackingV2({
                type: 'course', // 类型 课程/训练营
                courseId: this.courseId, // 课程id
                classId: this.classId, // 课时id
                contentType: this.liveDetail.content_type, // 内容类型
                contentId: '', // 内容id
                mediaType: '', // 媒体类型，训练营要区分音频和视频 audio video
                disabled: true
              })
              this.trackObj.pageEnter()
            }
            this.initIm(ticket).catch(e => {})
          }).catch(e => {})
        }, (e: any) => {})
      }).catch((e: any) => {
        this.loading = false
        this.handleError({text: '用户信息获取失败'})
      })
    })
  }
  beforeDestroy() {
    clearInterval(this.localTimer)
    clearTimeout(this.groupInfoLoopTimer)
    this.logout()
    this.setLeaveCache()
    if (this.trackObj) {
      this.trackObj.pageLeave()
    }
    window.removeEventListener('unload', this.unloadHandler, false)
  }
  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.trackObj) {
      this.trackObj.pageLeave()
      // ClassTracking.classLeave({classId: this.classId, courseId: this.courseId})
    }
    next()
  }
  beforeRouteEnter(to: Route, from: Route, next: any) {
    next((vm: any) => {
      vm.from = from
    })
  }
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    next()
    window.location.reload()
  }

  speedChangedHandler($event: number) {
    (this.$refs.videoArea as VideoArea).changeSpeed($event)
    this.mediaSpeed = $event
  }

  get liveMode() {
    let mode = 'video'
    if (this.liveDetail.live_type === 1) {
      mode = 'audio'
    } else if (this.liveDetail.live_type === 6) {
      mode = 'ppt'
    }
    return mode
  }

  get themeColor() {
    return 'red'
  }
  // 消息列表
  get msgList() {
    // 防止 发消息后立即切换到未加载历史消息的 消息列表 出现两条重复信息 此处去重处理
    let list = uniqueArray(this.listArr[this.listType], 'seq')
    return list
    function uniqueArray(array: any, key: any) {
      var result = array[0] ? [array[0]] : []
      for (var i = 1; i < array.length; i++) {
        var item = array[i]
        var repeat = false
        for (var j = 0; j < result.length; j++) {
          if (item[key] === result[j][key]) {
            repeat = true
            break
          }
        }
        if (!repeat) {
          result.push(item)
        }
      }
      return result
    }
  }
  set msgList(val) {
    this.$set(this.listArr, this.listType, val)
  }

  get sortMsgList(): Array<any> {
    let sortList = [].concat((this.msgList as any)).sort((object1: any, object2: any) => {
      var value1 = object1['seq']
      var value2 = object2['seq']
      if (value1 < value2) {
        return -1
      } else if (value1 > value2) {
        return 1
      } else {
        return 0
      }
    })
    return sortList
  }

  get unseeCount() {
    return this.unseeCountArr[this.listType]
  }
  set unseeCount(val) {
    this.$set(this.unseeCountArr, this.listType, val)
  }

  get pageSeq() {
    return this.pageSeqArr[this.listType]
  }
  set pageSeq(val) {
    this.$set(this.pageSeqArr, this.listType, val)
  }

  get pageSeqLoading() {
    return this.pageSeqLoadingArr[this.listType]
  }
  set pageSeqLoading(val) {
    this.$set(this.pageSeqLoadingArr, this.listType, val)
  }

  get liveState() {
    let sTime = this.liveDetail.start_timestamp
    let eTime = this.liveDetail.end_timestamp
    let cTime = this.localTime

    if (sTime) {
      if (cTime <= sTime) {
        // 预告
        return 0
      } else if (cTime > eTime) {
        // 结束
        return 2
      } else {
        // 进行中
        return 1
      }
    } else {
      return 0
    }
  }

  get memberInfo() {
    let memberId = this.loginInfo.identifier
    let memberCache = this.memberCache
    let memberInfo = memberCache[memberId]
    if (typeof memberInfo === 'undefined') {
      // 缓存中没有
      memberInfo = {}
    } else if (memberInfo === '0') {
      // 请求中
      memberInfo = {}
    }
    return memberInfo
  }

  get isAdmin() {
    return this.memberInfo.is_streamer
  }

  get curLiveAudioReadCache() {
    // 当前直播间的音频已读缓存
    let cache = this.audioReadCache
    let keys = Object.keys(cache)
    let result: any = {}
    keys.forEach((e: any) => {
      if (e.indexOf(this.aliveId) === 0) {
        let seq = e.split('_')[1]
        result[seq] = cache[e]
      }
    })
    return result
  }

  get nextUnreadAudio() {
    let audioArr: Array<any> = []
    let cache: any = this.curLiveAudioReadCache
    if (this.$refs.msgItem) {
      let msgItemArr = [].concat((this.$refs.msgItem as any)).sort((object1: any, object2: any) => {
        var value1 = object1.msgData['seq']
        var value2 = object2.msgData['seq']
        if (value1 < value2) {
          return -1
        } else if (value1 > value2) {
          return 1
        } else {
          return 0
        }
      })
      audioArr = msgItemArr.filter((e: any) => {
        return this.currentAudio.seq < e.msgData.seq && e.msgType === 'audio' && !cache[e.msgData.seq]
      })
    }
    return audioArr.length > 0 ? audioArr[0] : null
  }

  get nextAudio() {
    let audioArr = []
    let trigger = this.sortMsgList // 这个变量只是为了触发更新
    if (this.$refs.msgItem) {
      audioArr = this.audioList.filter((e: any) => {
        console.info(trigger[0])
        return this.currentAudio.seq < e.msgData.seq && e.msgType === 'audio'
      })
    }
    return audioArr.length > 0 ? audioArr[0] : null
  }

  get audioList() {
    let audioArr = []
    let trigger = this.sortMsgList // 这个变量只是为了触发更新
    if (this.$refs.msgItem) {
      audioArr = (this.$refs.msgItem as any).filter((e: any) => {
        console.info(trigger[0])
        return e.msgType === 'audio'
      })
      audioArr.sort((a: any, b: any) => {
        return a.msgData.seq < b.msgData.seq ? -1 : 1
      })
    }
    return audioArr
  }

  get firstAudio() {
    return this.audioList.length > 0 ? this.audioList[0] : null
  }

  get lastAudio() {
    let audios = this.audioList
    return audios.length > 0 ? audios[audios.length - 1] : null
  }

  get curAudio() {
    let audioArr = []
    let curSeq = this.currentAudio.seq
    if (this.$refs.msgItem) {
      audioArr = (this.$refs.msgItem as any).filter((e: any) => {
        return e.msgType === 'audio' && curSeq === e.msgData.seq
      })
    }
    return audioArr.length > 0 ? audioArr[0] : null
  }

  get showCurAudioBtn() {
    let flag = false
    // let down = false // 音频相对于当前方向
    let audioElm: any = this.$refs.liveAudio
    let curScroll = Math.abs(this.curScroll)
    if (this.curAudio && (audioElm && !audioElm.paused)) {
      let audioTop = this.curAudio.$el.offsetTop
      let audioH = this.curAudio.$el.offsetHeight
      let listH = (this.$refs.listWrap as HTMLDivElement).offsetHeight
      if (audioTop + audioH < curScroll) {
        // 上边界
        flag = true
        // down = false
      } else if (audioTop + audioH > curScroll + listH + audioH) {
        // 下边界
        flag = true
        // down = true
      }
    }
    return flag
  }

  get userDetail() {
    return this.$store.state.userInfo
  }

  get showRemindBar() {
    let beforeMin = this.liveDetail.push_before_minutes
    let sTime = this.liveDetail.start_timestamp
    let cTime = this.localTime
    let beforeFlag = false
    if (sTime - cTime > beforeMin * 60) {
      beforeFlag = true
    }
    return this.liveDetail.opened_notice_pushing && !this.liveDetail.pushed && beforeFlag && !this.courseId
  }

  get allShutup() {
    return typeof this.liveDetail.gag === 'undefined' ? false : !!this.liveDetail.gag
  }

  get topHeader() {
    return this.$store.state.$topHeader
  }

  @Watch('unseeCount', {immediate: true})
  onUnseeCountChanged(nVal: number) {
    if (nVal > 0) {
      this.showMsgTips = true
    }
  }

  @Watch('allShutup', {immediate: true})
  onAllShutupChanged(nVal: boolean, oVal: boolean) {
    if (typeof oVal !== 'undefined' && oVal !== nVal) {
      this.allShutupVisible = true
    }
    clearTimeout(this.allShutupVisibleTimer)
    this.allShutupVisibleTimer = setTimeout(() => {
      this.allShutupVisible = false
    }, 3000)
  }
  @Watch('topHeader', {immediate: true})
  onTopHeaderChanged() {
    if (this.topHeader) {
      (this.topHeader as ComponentTopHeader).emptySerachValue()
    }
  }
  @Watch('listType')
  onListTypeChanged() {
    this.listTypeChange()
  }
  @Watch('questionVisible')
  onQuestionVisibleChanged(nVal: boolean) {
    if (!nVal) {
      // 退出回答状态
      (this.$refs.inputBar as any).setMsgType(0)
      // 刚回答完退出 回答消息还没到服务器
      // 这里做 2s 延迟查询
      setTimeout((e: any) => {
        this.checkHasNewQuestion()
      }, 2000)
    }
    this.listTypeChange()
  }
  @Watch('curScroll')
  onCurScrollChanged(nVal: number) {
    // 与底部距离 50 则关闭自动置底
    let itemList = (this.$refs.itemList as HTMLDivElement)
    let wraperHeight = (this.$refs.listWrap as HTMLDivElement).offsetHeight
    this.autoToBottom = itemList.offsetHeight - wraperHeight - Math.abs(nVal) < 50
    if (this.autoToBottom) {
      // 到底后 未读消息 清0
      this.unseeCount = 0
    }

    // 小于加载触发边界 && 不在加载中 && 有下一页
    if (Math.abs(nVal) < 200 && !this.pageSeqLoading && this.pageSeq !== 0) {
      this.onPullingDown()
    }
  }
  @Watch('memberCacheArr')
  onMemberCacheArrChanged(nVal: Array<StudioMemberInfo>) {
    if (nVal.length > 0) {
      clearTimeout(this.memberCacheTimer)
      this.memberCacheTimer = setTimeout((e: any) => {
        this.getMemberInfo(nVal).catch((e: any) => {
          console.log(`member_info_error: ${JSON.stringify(e)}`)
        })
        this.memberCacheArr = []
      }, 100)
    }
  }
  @Watch('videoVisible')
  onVideoVisibleChanged(nVal: boolean) {
    if (nVal) {
      // 滚动在最底时 展开时 滚动会错位
      // 这里 做修正
      this.$nextTick(() => {
        let scroll = (this.$refs.scroll as any).scroll
        let offset = Math.abs(scroll.maxScrollY - scroll.y)
        // 210 是视频区的高
        if (offset === 210 || offset === 0) {
          this.$nextTick(() => {
            (this.$refs.scroll as any).scrollTo(0, scroll.maxScrollY)
          })
        }
      })
    }
  }
  @Watch('liveState', {immediate: true})
  onLiveStateChanged(nVal: boolean) {
    if (this.liveState > 0) {
      let isFree = this.liveDetail.is_free
      // 试学课时 || 已订阅课程 非训练营课时
      if ((isFree || this.courseDetail.is_subscribe)) {
        this.$nextTick(() => {
          this.trackObj.setDisabled(false)
        })
      }
    }
  }


  getLeaveCache() {
    let cacheData = utils.storage.getStorage('liveRoomLeaveCache')
    let cur = (new Date()).getTime()
    Object.keys(cacheData).forEach((e: any) => {
      let v = cacheData[e]
      if (cur > v.expTime) {
        delete cacheData[e]
      }
    })
    return cacheData
  }
  setLeaveCache() {
    let cacheData = this.getLeaveCache() || {}
    let cur = (new Date()).getTime()
    let cacheId = this.$route.params.id
    cacheData[cacheId] = {
      expTime: cur + (60 * 60 * 1000),
      currentAudio: this.currentAudio,
      currentVideoTime: this.currentVideoTime
    }
    utils.storage.setStorage('liveRoomLeaveCache', cacheData, 60 * 60 * 24)
  }
  initDetail() {
    let api = this.getLiveInfo
    return api().then(res => {
      this.liveDetail = Object.assign({}, this.liveDetail, res.data)
      this.loading = false
      // 触发直播状态(预告 结束) 更新
      this.localTime = this.liveDetail.now_time // 服务器时间
      this.localTimer = setInterval((e: any) => {
        this.localTime += 1
      }, 1000)

      // audioEvent
      // loading 为 false 时组件下次渲染才有 audio标签
      this.$nextTick(() => {
        this.initAudioEvent()
      })

      // 页面分享 与 标题
      this.initPageSetting()

      // 取历史消息
      this.initMsg().then((e: any) => {
        this.audioLoading = false
      })

      // 检测是否有新问题
      // this.checkHasNewQuestion()

      this.$nextTick(() => {
        let from = this.from
        if (from && from.name === 'nStudioPPT') {
          (this.$refs.enterDialog as any).hide()
        }
      })
    })
  }
  initIm(ticket: any) {
    // 1. 取sig
    // 2. im 登录
    // 3. 设置用户 别名 头像
    // 4. 加入 群组

    return this.getSig(ticket).catch((e: any) => {
      this.$message('im 取 Sig 失败')
      //          this.handleError({
      //            text: 'im 取 Sig 失败'
      //          })
      return Promise.reject(new Error(''))
    }).then(({userSig, sdkAppId}) => {
      return this.login(userSig, sdkAppId).catch((e: any) => {
        this.$message('im 登入失败')
        //            this.handleError({
        //              text: 'im 登入失败'
        //            })
        return Promise.reject(new Error(''))
      })
    }).then((e: any) => {
      return this.setProfile().catch((e: any) => {
        if (e && e.ErrorCode === -2) {
          // cancel 的不上报
          this.$message('im 设置用户信息失败')
        } else {
          if (e && e.ErrorCode === 40005) {
            // 名字带敏感词
            this.$message('昵称带有敏感信息，请在个人中心修改昵称后重新进入')
          } else {
            this.$message('im 设置用户信息失败')
          }
        }
        //            this.handleError({
        //              text: 'im 设置用户信息失败'
        //            })
        return Promise.reject(new Error(''))
      })
    }).then((e: any) => {
      let groupID = this.groupID
      return this.joinGroup(groupID).catch((e: any) => {
        this.$message('im 入群失败')
        //            this.handleError({
        //              text: 'im 入群失败'
        //            })
        return Promise.reject(new Error(''))
      })
    }).then((e: any) => {
      // 开启人数轮询
      this.loopGetGroupInfo()

      // 当前 用户信息
      this.getMemberInfo([this.loginInfo.identifier]).then((e: any) => {
        this.imLoading = false
      }).catch((e: any) => {
        this.handleError({text: '成员信息 - 获取失败 : ' + e.errorObj.text})
      })
    })
  }
  initMsg() {
    // 讨论区 历史记录
    return this.getMsg(1).then(msgList => {
      if (msgList.length > 0) {
        this.$set(this.listArr, 1, this.listArr[1].concat(msgList))
      }
      this.scrollUpdate()
      this.toBottom()
    })
    // // 讲师区 历史记录
    // return this.getMsg(0, msgCount).then(msgList => {
    //   if (msgList.length > 0) {
    //     this.$set(this.listArr, 0, this.listArr[0].concat(msgList))
    //   }
    //   this.scrollUpdate()
    //   this.toBottom()
    // })
  }
  initAudioEvent() {
    let audio = (this.$refs.liveAudio as any)
    let $emitArr = [
      'timeupdate',
      'canplay',
      'loadedmetadata',
      'error',
      'ended',
      'play',
      'playing',
      'waiting'
    ]
    $emitArr.forEach((e: any) => {
      audio.addEventListener(e, (event: any) => {
        debuglog && console.info('音频 触发:', 'index.liveAudio.' + e)
        eventBus.$emit('index.liveAudio.' + e, {event, seq: this.currentAudio.seq, audioDom: audio})
      })
    })
    let $onArrr = [
      'load',
      'play',
      'pause'
    ]
    $onArrr.forEach((e: any) => {
      eventBus.$on('index.liveAudio.do' + e, (data: any) => {
        debuglog && console.info('音频 执行:', 'index.liveAudio.do' + e)
        // 如果 currenAudio 中有图片 ppt 切换图片
        if (e === 'play' && this.currentAudio.pptImg) {
          (this.$refs.pptArea as any).to(this.currentAudio.pptImg)
        }
        audio[e]()
      })
    })

    // 音频已读缓存
    let readCache = utils.storage.getStorage('audioReadCache') || {}
    this.audioReadCache = Object.assign({}, this.audioReadCache, readCache)
    eventBus.$on('index.setReadAudio', ({seq}:any) => {
      let cache = this.audioReadCache
      cache[this.aliveId + '_' + seq] = 1
      this.audioReadCache = Object.assign({}, this.audioReadCache, cache)
      utils.storage.setStorage('audioReadCache', cache, 7 * 24 * 60 * 60) // 存一周
    })

    eventBus.$on('index.pauseAudio', () => {
      debuglog && console.info('音频 触发停止:')
      this.curAudio && (this.curAudio.$children[0] as any).pause(true)
    })

    eventBus.$on('index.liveAudio.timeupdate', ({event, seq, audioDom}:any) => {
      this.currentAudio = Object.assign({}, this.currentAudio, {
        time: audioDom.currentTime
      })
    })

    eventBus.$on('index.setAudioCurrentTime', ({time}:any) => {
      audio.currentTime = time
    })

    // 音频连播
    audio.addEventListener('ended', (event: any) => {
      debuglog && console.info('音频 触发连播:')
      this.nextAudio && (this.nextAudio.$children[0] as any).play()
    })
  }

  getLiveInfo() {
    let courseId = this.courseId
    let api
    if (courseId) {
      // 是课程直播
      // 这里不能用 this.aliveId 接口返回后会重写 aliveId 为响应中的 content_id
      api = getClassCourseDetail({
        course_id: courseId,
        class_id: this.$route.params.id
      })
    } else {
      api = getContentDetail(this.aliveId)
    }

    return api.then((res) => {
      if (res.error) return Promise.reject(res)
      if (courseId) {
        // 格式化数据 与 直播接口 大致一致
        let nObj = {
          ...res,
          ...res.content
        }
        // im 聊天室id 为 content_id
        this.groupID = res.content.content_id
        // aliveId 为 content_id
        this.aliveId = res.content.content_id
        return getCourseDetail(courseId).then((courseDetail: CourseInfo) => {
          let canRead = false
          let isFree = res.is_free
          this.courseDetail = courseDetail
          if (isFree || courseDetail.is_subscribe) {
            // 是试看内容
            canRead = true
            if (res.content.live_state === 1 || res.content.live_state === 2) {
              // ClassTracking.classEnter({classId: this.classId, courseId: this.courseId})
              // setTimeout(() => {
              //   ClassTracking.classLeave({classId: this.classId, courseId: this.courseId})
              //   ClassTracking.checkUpload()
              //   ClassTracking.classEnter({classId: this.classId, courseId: this.courseId})
              // }, 2000)
            }
          } else if (courseId) {
            // 未订阅 又不是 试看 去课程页
            this.$router.replace({
              name: 'course',
              params: {
                id: courseId
              }
            })
          }
          return canRead ? Promise.resolve({ data: nObj }) : Promise.reject(new Error(''))
        })
      } else {
        return res
      }
    }).catch((rej) => {
      if (rej.error === 'no-pay') {
        if (courseId) {
          // 是课程直播
          // 未订阅
          this.$router.replace({
            name: 'course',
            params: {
              id: courseId
            }
          })
        } else {
          // 直播
          this.$router.replace({
            name: 'content',
            params: {
              type: 'live',
              id: this.aliveId
            }
          })
        }
      } else {
        this.errorObj = {
          html: rej.message
        }
        this.loading = false
      }
      return Promise.reject(new Error(''))
    })
  }
  getSig(ticket: any) {
    return studioApi.chatgroup_cert({live_id: this.groupID, ticket}).then((e: any) => {
      return {
        userSig: e.sig,
        sdkAppId: e.sdk_appid
      }
    })
  }
  joinGroup(groupID: string) {
    var options = {
      'GroupId': groupID //  群id
    }
    return new Promise((resolve, reject) => {
      webim.applyJoinBigGroup(
        options,
        function (resp: any) {
          //  JoinedSuccess:加入成功 WaitAdminApproval:等待管理员审批
          if (resp.JoinedStatus && resp.JoinedStatus === 'JoinedSuccess') {
            debuglog && console.info('im 加入房间 - 成功')
            resolve()
          } else {
            debuglog && console.info('im 加入房间 - 失败:' + resp.JoinedStatus)
            reject(new Error(''))
          }
        },
        function (err: any) {
          if (err.ErrorCode === 10013) {
            // 已加入
            debuglog && console.info('im 加入房间 - 已加入过')
            resolve()
          } else if ((err.ErrorCode === -12)) {
            debuglog && console.info('im 加入房间 - 非正常加入')
            resolve()
          } else {
            debuglog && console.info('im 加入房间 - 失败:' + err.ErrorInfo)
            reject(new Error(''))
          }
        }
      )
    })
  }
  login(userSig: string, sdkAppId: string) {
    //  loginInfo
    //  listeners
    //  收群信息
    //  用户socket信息
    //  options
    let {avatar, nick_name, uid: id} = this.userDetail
    let loginInfo = {
      'sdkAppID': sdkAppId, //  用户所属应用id,必填
      'appIDAt3rd': sdkAppId, //  用户所属应用id，必填
      'accountType': 34076, //  用户所属应用帐号类型，必填
      'identifier': id, //  当前用户ID,必须是否字符串类型，选填
      'identifierNick': nick_name, //  当前用户昵称，选填
      'userSig': userSig, //  当前用户身份凭证，必须是字符串类型，选填
      'headurl': avatar //  当前用户默认头像，选填
    }
    let listeners = {
      'onConnNotify': this.handleLink, // 选填
      'jsonpCallback': (rspData: any) => {
        webim.setJsonpLastRspData(rspData)
      }, // IE9(含)以下浏览器用到的jsonp回调函数,移动端可不填，pc端必填
      'onBigGroupMsgNotify': (e: any) => {}, // 监听新消息(大群)事件，必填
      'onMsgNotify': this.handleNotify, // 监听新消息(私聊(包括普通消息和全员推送消息)，普通群(非直播聊天室)消息)事件，必填
      'onGroupSystemNotifys': {  // 监听（多终端同步）群系统消息事件，必填
        '255': this.handleSystemNotifys
      },
      'onGroupInfoChangeNotify': () => {
      }// 监听群资料变化事件，选填
    }
    let options = {
      isLogOn: false //  控制台日志输出
    }

    this.loginInfo = loginInfo
    return new Promise((resolve, reject) => {
      webim.login(
        loginInfo,
        listeners,
        options,
        () => {
          debuglog && console.info('im 登录 - 成功')
          resolve()
        },
        (err: any) => {
          debuglog && console.info('im 登录 - 失败' + err.ErrorInfo)
          reject(err)
        }
      )
    })
  }
  setProfile() {
    let {nick_name, avatar} = this.userDetail
    let options = {
      'ProfileItem': [
        {
          'Tag': 'Tag_Profile_IM_Nick',
          'Value': nick_name
        },
        {
          'Tag': 'Tag_Profile_IM_Image',
          'Value': avatar
        }
      ]
    }
    return new Promise((resolve, reject) => {
      webim.setProfilePortrait(
        options,
        () => {
          debuglog && console.info('im 设置用户信息 - 成功')
          resolve()
        },
        (err: any) => {
          debuglog && console.info('im 设置用户信息 - 失败', err)
          reject(err)
        }
      )
    })
  }
  logout() {
    this.msgList = []
    // 登出
    webim.logout((e: any) => {
      debuglog && console.info('im 登出 - 成功')
    })
    // 退群
    let opt = {
      GroupId: this.groupID
    }
    webim.quitBigGroup(opt, (e: any) => {
      debuglog && console.info('im 退群 - 成功')
    }, (e: any) => {
      debuglog && console.info('im 退群 - 失败:', e)
    })
  }

  // 信息事件相关
  handleSystemNotifys(systemMsg: any) {
    // 系统 信息回调
    if (systemMsg.GroupId === this.aliveId) {
      // 是当前群才处理
      try {
        let data = JSON.parse(systemMsg.UserDefinedField)
        // 禁言消息
        // 自定义消息约定
        // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form
        if (data.blocked) {
          this.handleShutupMember({
            members: data.blocked,
            block: data.shutup_time !== 0
          })
        }
        if (data.event) {
          if (data.event === 'end') {
            // 后台结束直播
            this.endLive()
          } else if (data.event === 'member_privilage_change') {
            // 讲师信息改变
            this.memberChange(data.members)
          } else if (data.event === 'block_all') {
            // 全员禁言改变
            this.updateAllShutup(data.shutup_time !== 0)
          } else if (data.event === 'update_slider_config') {
            // ppt 内容更新
            (this.$refs.pptArea as any).getPPTData()
          }
        }
      } catch (e) {
        // raven 错误记录
      }
    }
  }
  handleNotify(msgList: any) {
    //  聊天群 信息回调
    debuglog && console.info(msgList)
    if (msgList.length > 0) {
      // 消息格式化
      // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/get-messages-history
      // 这里有个坑
      // 这里信息回调的 elems 格式 和 历史消息接口的 body 格式不一致
      // 需要做处理
      let formatList1: Array<StudioMsgData> = [] // 讲师区
      let formatList2: Array<StudioMsgData> = [] // 讨论区
      msgList.forEach((e: any) => {
        let msg: any;
        e.elems.forEach((v: any) => {
          v.MsgContent = v.content
          v.MsgType = v.type
          upperKey(v.MsgContent)
          // 图片类型
          if (v.MsgContent['ImageInfoArray']) {
            v.MsgContent['ImageInfoArray'].forEach((e: any) => {
              upperKey(e)
              // Url 特别点是全大写
              e.URL = e.Url
            })
          }
        })
        msg = {
          seq: e.seq,
          sender: {
            uid: e.fromAccount
          },
          create_time: e.time,
          body: e.elems
        }
        let mType = msgType(msg)
        let isTecher = this.liveDetail.live_person.some((e: any) => e.id === msg.sender.uid)
        if (mType === 'QA') {
          let content = JSON.parse(msg.body[0].MsgContent.Data)
          // 不是回答的 (没有回答数据)
          if (!content.ABody) {
            // 如果是问题类型 问题区tab 标红点
            this.newQuestion = true
          }
        }
        // 是当前群信息才处理
        if (e.sess._impl.id === this.groupID && mType !== '') {
          // 收到信息 如果不是讲师的 不加入讲师区列表
          isTecher && formatList1.push(msg)
          formatList2.push(msg)
        }
      })
      this.$set(this.listArr, 0, this.listArr[0].concat(formatList1))
      this.$set(this.listArr, 1, this.listArr[1].concat(formatList2))
      if (this.autoToBottom) {
        // 有新消息则自动置底
        this.toBottom()
      } else {
        // 没有置底 未看数量增加
        this.unseeCount += msgList.length
      }
    }

    function upperKey(obj: any) {
      for (let name in obj) {
        let upperName = name.substring(0, 1).toUpperCase() + name.substring(1)
        obj[upperName] = obj[name]
      }
    }
    function msgType(msg: StudioMsgData) {
      let curType = msg.body[0].MsgType
      let type = webim.MSG_ELEMENT_TYPE
      let clsMap = {
        [type.TEXT]: 'text', // 文本
        [type.IMAGE]: 'img', // 图片
        [type.CUSTOM]: 'custom' // 自定义
      }
      let mapType = clsMap[curType]
      if (mapType === 'custom') {
        // 自定义消息约定
        // https://git.hogecloud.com/allen/duanshu-backend-extend/wikis/api/live/client/custom-message-form
        let customMap: any = {
          QA: 'QA',
          admire: 'admire',
          audio: 'audio'
        }
        mapType = customMap[msg.body[0].MsgContent.Ext]
      }
      return mapType || ''
    }
  }
  handleLink(resp: any) {
    let state = ''
    //  当前 socket 链接 信息回调
    switch (resp.ErrorCode) {
      case webim.CONNECTION_STATUS.ON:
        break
      case webim.CONNECTION_STATUS.OFF:
        state = '连接已断开，请检查下您的网络是否正常'
        break
      default:
        state = '未知连接状态, 请刷新重试'
        break
    }
    if (state === '') {

    } else {
      state && this.handleError({
        text: state
      })
    }
  }
  handleError({text}: any) {
    //  异常 信息回调
    let msg = text || 'im 初始化失败'
    this.errorObj = {
      html: msg
    }
  }
  handleDelMsg(msgData: StudioMsgData) {
    // 删除信息
    for (let j = 0, len = this.listArr.length; j < len; j++) {
      let msgList = this.listArr[j]
      for (let i = 0, len = msgList.length; i < len; i++) {
        let msg = msgList[i]
        if (msg.seq === msgData.seq) {
          if (this.currentAudio.seq === msgData.seq) {
            // 删除的是当前播放的语音 则暂停播放
            eventBus.$emit('index.liveAudio.dopause')
          }
          this.listArr[j].splice(i, 1)
          this.scrollUpdate()
          break
        }
      }
    }
  }
  handleShutupMember({members, block}: any) {
    // 禁言用户
    members.forEach((e: any) => {
      if (e === this.memberInfo.uid) {
        let text = block ? '您已被 禁言' : '您的禁言状态 已解除'
        this.$message(text)
      }
      this.memberCache[e].blocked = block
    })
  }
  handleTools(curMsgData: StudioMsgData) {
    // 独显一个 tool-tips
    let seq = curMsgData ? curMsgData.seq : -1
    this.$refs.msgItem && (this.$refs.msgItem as any).forEach((e: any) => {
      if (e.msgData.seq !== seq) {
        e.showTools = false
      }
    })
  }
  handleAnswer(msgData: StudioMsgData) {
    this.answerTarget = msgData
    ;(this.$refs.inputBar as any).setMsgType(2)
    ;(this.$refs.inputBar as any).focus()
  }

  // 取信息
  getGroupInfo() {
    return new Promise((resolve, reject) => {
      webim.getGroupInfo({
        'GroupIdList': [this.groupID],
        'GroupBaseInfoFilter': [
          'CreateTime',
          'NextMsgSeq',
          'MemberNum',
          'MaxMemberNum'
        ]
      }, (e: any) => {
        resolve(e.GroupInfo[0])
      }, (e: any) => {
        reject(new Error('im 取群信息 - 失败'))
      })
    })
  }
  loopGetGroupInfo() {
    var self = this
    s()
    this.groupInfoLoopTimer = setInterval(s, this.groupInfoLoopTime * 1000)

    function s() {
      self.getGroupInfo().then((e: any) => {
        self.groupInfo = e
      }).catch((e: any) => {
        //            self.handleError({
        //              text: 'im 取群信息失败'
        //            })
      })
    }
  }
  getMsg(type?: number, count = 20) {
    // 拉取最新的群历史消息
    let listType = typeof type !== 'undefined' ? type : this.listType
    let onlyStreamer = listType === 0

    // 拉取最新的群历史消息
    let start = this.sortMsgList[0] ? this.sortMsgList[0].seq - 1 : ''
    var options = {
      live_id: this.groupID,
      only_streamer: onlyStreamer,
      seq: start, // 起始索引(最小1) : 最大数 - 当前列表数 - 获取数
      size: count // 向前取多少
    }
    return studioApi.chatgroup_messages(options).then(res => {
      let msgList = res.data
      // 收集用户信息
      msgList.forEach((msg: StudioMsgData) => {
        let sender = msg.sender
        if (sender.uid && typeof this.memberCache[sender] === 'undefined') {
          this.$set(this.memberCache, sender.uid, sender)
        }
      })
      this.pageSeq = res.previous_seq
      return msgList
    }).catch((e: any) => {
      debuglog && console.info(e)
    })
  }
  checkHasNewQuestion() {
    let params = {
      live_id: this.groupID,
      answered: false,
      page: 1,
      size: 1
    }
    studioApi.chatgroup_qa(params).then((e: any) => {
      this.newQuestion = e.data.length > 0
    }).catch(err => {
      this.$message(`qa繁忙：${err.errorObj.text}`)
    })
  }

  loopImTicket() {
    return new Promise((resolve, reject) => {
      this.getTicket().then((e: any) => {
        if (e.ticket) {
          // 完成
          this.ticketEnd = true
          resolve(e.ticket)
        } else {
          // 继续
          this.ticketEnd = false
          this.ticketMsg = e.wait || 5
          this.ticketTimer = setInterval(() => {
            this.ticketMsg = this.ticketMsg - 1
            if (this.ticketMsg < 1) {
              clearInterval(this.ticketTimer)
              this.loopImTicket().then(resolve)
            }
          }, 1000)
        }
      }, () => {
        this.$message('ticket 服务器繁忙 正在重试')
        this.ticketMsg = 3
        this.ticketTimer = setInterval(() => {
          this.ticketMsg = this.ticketMsg - 1
          if (this.ticketMsg < 1) {
            clearInterval(this.ticketTimer)
            this.loopImTicket().then(resolve)
          }
        }, 1000)
      })
    })
  }

  getTicket() {
    return studioApi.getChatgroupTicket({liveId: this.aliveId})
  }

  // 滚动相关
  scrollUpdate() {
    (this.$refs.scroll as any) && (this.$refs.scroll as any).forceUpdate(true)
  }
  scrollingWatcher({y}: any) {
    this.curScroll = y
  }
  onPullingDown() {
    // 防止异步过慢 插入错位
    let listIdx = this.listType
    // 开 loading
    this.pageSeqLoading = true
    this.getMsg().then(msgList => {
      let itemList = (this.$refs.itemList as any)
      let beforeH = itemList.offsetHeight
      let beforeScroll = this.curScroll
      if (msgList.length > 0) {
        this.$set(this.listArr, listIdx, this.listArr[listIdx].concat(msgList))
      }
      // 关 loading
      this.$set(this.pageSeqLoadingArr, this.listType, false)
      // 滚前刷新
      this.scrollUpdate()
      this.$nextTick(() => {
        let afterH = itemList.offsetHeight
        let afterScroll = beforeScroll - (afterH - beforeH)
        ;(this.$refs.scroll as any).scrollTo(0, afterScroll, 0, 'learn')
        // 滚后刷新
        this.scrollUpdate()
      })
    })
  }
  toTop() {
    let scroll = (this.$refs.scroll as any)
    scroll.scrollTo(0, 0)
    this.curScroll = scroll.scroll.y
  }
  toBottom() {
    let scroll = (this.$refs.scroll as any)
    let wraper = (this.$refs.listWrap as HTMLDivElement)
    let wraperHeight = wraper ? wraper.offsetHeight : 0
    // 滚前刷新
    this.scrollUpdate()
    this.$nextTick(() => {
      let itemList = (this.$refs.itemList as HTMLDivElement)
      if (itemList) {
        // 内容高 超过 容器才滚
        if (wraperHeight < itemList.offsetHeight) {
          scroll.scrollTo(0, wraperHeight - itemList.offsetHeight)
        }
        // 清空未读
        this.unseeCount = 0
        // 滚后刷新
        this.scrollUpdate()
        this.curScroll = scroll.scroll.y
      }
    })
  }
  listTypeChange() {
    this.$nextTick(() => {
      // listType 值更新后
      // 内容改变 刷新滚动组件
      this.scrollUpdate()
      this.toBottom()
    })
  }

  // 成员信息缓存
  getMemberInfo(members: any) {
    members.forEach((member: number) => {
      // 标识为0 以示为请求中
      if (!this.memberCache[member]) {
        this.$set(this.memberCache, member, '0')
      }
    })
    return studioApi.chatgroup_member({live_id: this.groupID, members}).then((e: any) => {
      let membersData = e
      // 成不出此成员时 e 为空对象
      // 有成员时 e 为数组
      if (membersData.length > 0) {
        membersData.forEach((member: StudioMemberInfo) => {
          this.$set(this.memberCache, member.uid, member)
        })
      }
    })
  }
  getMemberHandler(member: StudioMemberInfo) {
    this.memberCacheArr.indexOf(member) === -1 && this.memberCacheArr.push(member)
  }

  toCurAudio() {
    if (this.curAudio) {
      this.toElement(this.curAudio.$el)
      this.curAudio.heighLight()
    }
  }
  toElement(elm: any) {
    let cubeScroll = (this.$refs.scroll as any)
    this.$nextTick(() => {
      cubeScroll.scroll.scrollToElement(elm)
      this.curScroll = cubeScroll.scroll.y
    })
  }

  initPageSetting() {
    // 页面标题
    // utils.windowTitle(this.liveDetail.title)
    // let img = this.liveDetail.indexpic
    // let title = this.liveDetail.title
    // let desc = this.liveDetail.brief
    // let routerObj = {
    //   name: 'Brilive',
    //   params: {
    //     id: this.aliveId
    //   }
    // }
    // if (this.courseId) {
    //   // 课程
    //   routerObj = {
    //     name: 'Bricourse',
    //     params: {
    //       id: this.courseId
    //     }
    //   }
    //   img = this.courseDetail.indexpic
    //   title = this.courseDetail.title
    //   desc = this.courseDetail.brief
    //   if (this.$route.query.type) {
    //     img = this.courseDetail.cover_image
    //     title = this.courseDetail.name
    //     desc = this.courseDetail.brief
    //     routerObj = {
    //       name: `Bri${this.$route.query.type}`,
    //       params: {
    //         id: this.courseId
    //       }
    //     }
    //   }
    // }
    //
    // let { location: { path: hashPath } } = this.$router.resolve(routerObj)
    // let link = window.location.href.split('#')[0] + '#' + hashPath
    //
    // // 页面分享
    // goShare({
    //   shareInfo: {
    //     title,
    //     desc,
    //     link,
    //     imgUrl: utils.createImgsrc(img)
    //   }
    // })
  }
  getTime(dateString?: string) {
    let date = dateString ? new Date(Date.parse(dateString.replace(/-/g, '/'))) : new Date()
    return Math.ceil(date.getTime() / 1000)
  }
  endLive() {
    this.getLiveInfo().then(res => {
      let videoArea: any = this.$refs.videoArea
      this.liveDetail = Object.assign({}, this.liveDetail, res.data)
      if (videoArea) {
        videoArea.stop()
      }
    })
  }
  memberChange(changeData: any) {
    this.getLiveInfo().then(res => {
      let data = res.data
      let techers = data.live_person
      // 讲师列表 缓存
      this.liveDetail.live_person = techers
    })

    // 更新消息用户信息缓存
    let reloadMemberId = []
    for (let t in changeData) {
      let targetCache = this.memberCache[t]
      if (typeof targetCache !== 'undefined') {
        // 本地有相应缓存 更新緩存
        reloadMemberId.push(t)
      }
    }
    reloadMemberId.length > 0 && this.getMemberInfo(reloadMemberId).catch(() => {
    })
  }
  ajaxShutup(flag: boolean) {
    studioApi.blockall({liveId: this.aliveId, block: !!flag}).then(() => {
      this.updateAllShutup(!!flag)
    })
  }
  updateAllShutup(flag: boolean) {
    this.liveDetail.gag = flag ? 1 : 0
  }
  enterDialogOk() {
    if (this.liveState === 0) {
      // 未开始
      // 咩到唔做
    } else if (this.liveMode === 'video') {
      (this.$refs.videoArea as any).play()
    }
  }
}
