









import { Component, Vue, Prop } from 'vue-property-decorator'
import {getH5Host, getH5Url} from '@/utils/utils'
const uuidv4 = require('uuid/v4')

  @Component({
  })
export default class StudioCodeGuide extends Vue {
  @Prop() liveDetail!: any
  qrcodeId: string = uuidv4()
  mounted() {
    let host = getH5Host()
    let url = `${host}/?#/nStudio/${this.$route.params.id}`
    if (this.$route.params.courseId) {
      url = `${host}/?#/courseStudio/${this.$route.params.id}/${this.$route.params.courseId}?classContentId=${this.$route.query.classContentId}`
    }

    this.$nextTick(() => {
      const QRCode = require('@keeex/qrcodejs-kx')
      let qrcode = new QRCode(this.qrcodeId, {
        width: 210, // 设置宽度，单位像素
        height: 210, // 设置高度，单位像素
        text: url // 设置二维码内容或跳转地址
      })
    })
  }
}
