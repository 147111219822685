import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class StudioMemberCacheMixins extends Vue {
  @Prop() memberCache?: any

  memberCacheGetMemberInfo(memberId: string) {
    let memberCache = this.memberCache
    let memberInfo = memberCache[memberId]
    let defautData = {
      nickname: '...',
      avatar: '/static/img/mine/avator.jpg'
    }
    if (typeof memberInfo === 'undefined') {
      // 缓存中没有
      this.$emit('getMember', memberId)
      memberInfo = defautData
    } else if (memberInfo === '0') {
      // 请求中
      memberInfo = defautData
    }
    return memberInfo
  }
}
