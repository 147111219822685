







import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
import StudioMemberCacheMixins from './memberCache'
  @Component({})
export default class StudioMsgAdmire extends Mixins(StudioMsgItemMixins, StudioMemberCacheMixins) {
  get jsonData() {
    return JSON.parse(this.msgContent.Data)
  }
  get fromMember() {
    let memberId = this.jsonData.from
    return this.memberCacheGetMemberInfo(memberId)
  }
  get toMember() {
    let memberId = this.jsonData.to
    return this.memberCacheGetMemberInfo(memberId)
  }
  get toMemberName() {
    // 讲师可能有别名
    let name = this.toMember.nickname
    if (this.toMember.nick_name) {
      // 讲师有别名
      name = this.toMember.nick_name
    }
    return name
  }
}
