import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
})
export default class StudioMsgItemMixins extends Vue {
  @Prop() msgData?: any
  @Prop() memberInfo?: any

  get msgContent() {
    return this.msgData.body[0].MsgContent
  }
  get isStreamer() {
    return this.memberInfo && this.memberInfo.is_streamer
  }

  urlToTagHtml(v: string) {
    let reg = /(https?):\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
    let nStr = v.replace(reg, $0 => {
      return '<a style="display:inline-block;word-break: break-all;" href="' + $0 + '">' + $0 + '</a>'
    })
    return nStr
  }
}
