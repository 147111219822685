







import { Component, Vue as VueType, Watch, Prop } from 'vue-property-decorator'

  @Component({
  })
export default class StudioLiveDesc extends VueType {
    @Prop({})
    liveDetail?: any

    defaultText: string = `1. 点击播放即可收看直播，请确保手机处于非静音状态\n2. 如遇到语音加载不出来，可刷新或是重新进入\n3. 可在讨论区发表想法和提问交流\n4. 直播结束后可观看回放，永久学习`
}
