






import { Component, Vue as VueType, Watch, Prop, Mixins } from 'vue-property-decorator'
import StudioMsgItemMixins from './mixins'
import {eventBus} from '../../event-bus'
  @Component({
  })
export default class StudioMsgImage extends Mixins(StudioMsgItemMixins) {
    loading: boolean = true

    get fullImg() {
      return this.msgContent.ImageInfoArray.filter((e: any) => e.Type === 1)[0]
    }
    get smallImg() {
      return this.msgContent.ImageInfoArray.filter((e: any) => e.Type === 3)[0]
    }
    get imgStyleObj() {
      let style: any = {}
      if (this.smallImg.Height) {
        style.height = this.smallImg.Height / 2 + 'px'
      }
      return style
    }

    loaded() {
      this.loading = false
    }
    previewImg(url: string) {
      eventBus.$emit('index.previewImg', url)
    }
}
