










import { Component, Vue, Prop } from 'vue-property-decorator'
import { CourseInfo } from '@/modules/course/models'

  @Component({
  })
export default class StudioCourseInfo extends Vue {
    @Prop() courseDetail!: CourseInfo
    arrToggle: boolean = false
    toDetail() {
      this.$router.push({
        name: 'course',
        params: {
          id: this.courseDetail.content_id
        }
      })
    }
    toggle() {
      this.arrToggle = !this.arrToggle
      this.$emit('toggle', this.arrToggle)
    }
}
